var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "cyui-layout-wrapper"
  }, [_c('cyui-layout', {
    scopedSlots: _vm._u([{
      key: "header",
      fn: function () {
        return [_vm.isCustomHeader ? _c('cyui-header', {
          attrs: {
            "title": _vm.routeMetaTitle
          },
          on: {
            "go-back": _vm.goBack
          }
        }) : _vm._e()];
      },
      proxy: true
    }, {
      key: "default",
      fn: function () {
        return [_c('div', {
          staticClass: "page"
        }, [_c('van-cell-group', {
          staticClass: "cell-group"
        }, [_c('van-cell', {
          attrs: {
            "title": "盘点区域",
            "value": _vm.spaceFullName || '-'
          }
        })], 1), _c('div', {
          staticClass: "list"
        }, _vm._l(_vm.productList, function (item, index) {
          return _c('div', {
            key: index,
            staticClass: "item"
          }, [_c('div', {
            staticClass: "name"
          }, [_vm._v(_vm._s(item.productName || '-'))]), _c('div', {
            staticClass: "infos"
          }, [_c('div', {
            staticClass: "img"
          }, [_c('van-image', {
            attrs: {
              "width": "100%",
              "height": "100%",
              "fit": "cover",
              "lazy-load": "",
              "src": item.pictureUrl
            },
            on: {
              "click": function ($event) {
                return _vm.onPreview(item.pictureUrl);
              }
            }
          })], 1), _c('div', {
            staticClass: "attrs"
          }, [_c('van-row', [_c('van-col', {
            attrs: {
              "span": "15"
            }
          }, [_vm._v("主规格：" + _vm._s(item.standard || '-'))]), _c('van-col', {
            attrs: {
              "span": "9"
            }
          }, [_vm._v("单位：" + _vm._s(item.unit || '-'))]), _c('van-col', {
            attrs: {
              "span": "24"
            }
          }, [_vm._v("库存数量：" + _vm._s(item.stockNum || '-'))]), _c('van-col', {
            attrs: {
              "span": "24"
            }
          }, [_vm._v("资产编号：" + _vm._s(item.productNo || '-'))]), _c('van-col', {
            attrs: {
              "span": "24"
            }
          }, [_c('div', {
            staticClass: "stepper"
          }, [_vm._v("盘点数量："), _c('van-stepper', {
            attrs: {
              "min": 0,
              "integer": "",
              "max": item.productProperty === 3 ? 1 : undefined
            },
            on: {
              "change": val => _vm.onCheckNumChange(item, val)
            },
            model: {
              value: item.checkNum,
              callback: function ($$v) {
                _vm.$set(item, "checkNum", $$v);
              },
              expression: "item.checkNum"
            }
          })], 1)])], 1)], 1)]), _c('div', {
            staticClass: "actions"
          }, [_c('div', {
            staticClass: "actionLeft"
          }, [item.standardDesName ? _c('div', [_vm._v("品牌规格详情：" + _vm._s(item.standardDesName))]) : _vm._e()]), _c('van-button', {
            attrs: {
              "type": "primary"
            },
            on: {
              "click": function ($event) {
                return _vm.onSaveCheck(item);
              }
            }
          }, [_vm._v("保存")])], 1), item.isCheck ? _c('img', {
            staticClass: "isSave",
            attrs: {
              "src": require("./images/isSave.png")
            }
          }) : _vm._e()]);
        }), 0)], 1)];
      },
      proxy: true
    }, {
      key: "footer",
      fn: function () {
        return [_c('div', {
          staticClass: "footer"
        }, [_c('div', {
          staticClass: "addBtn"
        }, [_c('van-button', {
          attrs: {
            "block": "",
            "plain": "",
            "type": "primary",
            "icon": "add-o"
          },
          on: {
            "click": _vm.onAddItem
          }
        }, [_vm._v("添加该区域额外物资")])], 1), _c('div', {
          staticClass: "footerBtns"
        }, [_c('van-button', {
          attrs: {
            "block": "",
            "type": "primary",
            "size": "large"
          },
          on: {
            "click": _vm.onSubmit
          }
        }, [_vm._v("提交")]), _c('van-button', {
          attrs: {
            "block": "",
            "type": "primary",
            "size": "large"
          },
          on: {
            "click": _vm.goBack
          }
        }, [_vm._v("暂存并退出")])], 1)])];
      },
      proxy: true
    }])
  }), _c('van-dialog', {
    attrs: {
      "title": "注意",
      "show-cancel-button": "",
      "before-close": _vm.onSaveCheckReason
    },
    model: {
      value: _vm.saveCheckReasonShow,
      callback: function ($$v) {
        _vm.saveCheckReasonShow = $$v;
      },
      expression: "saveCheckReasonShow"
    }
  }, [_c('div', {
    staticClass: "rejectPanel"
  }, [_c('div', {
    staticClass: "tip"
  }, [_vm._v("您输入的盘点库与实际库存不符！ ")]), _c('van-field', {
    staticClass: "vtextarea",
    attrs: {
      "label": "请填写原因",
      "required": "",
      "type": "textarea",
      "rows": "3",
      "autosize": "",
      "maxlength": "200",
      "placeholder": "必填",
      "input-align": "left",
      "show-word-limit": ""
    },
    model: {
      value: _vm.reason,
      callback: function ($$v) {
        _vm.reason = $$v;
      },
      expression: "reason"
    }
  })], 1)]), _c('van-dialog', {
    attrs: {
      "title": "添加",
      "show-cancel-button": "",
      "before-close": _vm.onProductSelectConfirm
    },
    model: {
      value: _vm.productListShow,
      callback: function ($$v) {
        _vm.productListShow = $$v;
      },
      expression: "productListShow"
    }
  }, [_c('div', {
    staticClass: "productListPanel"
  }, [_c('van-search', {
    attrs: {
      "show-action": "",
      "shape": "round",
      "placeholder": "请输入资产名称"
    },
    on: {
      "search": _vm.onProductListSearch,
      "focus": function ($event) {
        _vm.productListResultShow = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "action",
      fn: function () {
        return [_c('div', {
          staticClass: "searchBtn",
          on: {
            "click": _vm.onProductListSearch
          }
        }, [_vm._v("搜索")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.productListSearchKeyword,
      callback: function ($$v) {
        _vm.productListSearchKeyword = $$v;
      },
      expression: "productListSearchKeyword"
    }
  }), _vm.productListResultShow ? _c('div', {
    staticClass: "productListResultPop"
  }, [_vm._l(_vm.productListGets, function (item, index) {
    return _c('van-cell', {
      key: item.id,
      attrs: {
        "clickable": ""
      },
      on: {
        "click": function ($event) {
          return _vm.onProductListSelect(item);
        }
      },
      scopedSlots: _vm._u([{
        key: "title",
        fn: function () {
          return [_c('div', {
            staticClass: "productListResultTitle"
          }, [_c('span', [_vm._v(_vm._s(item.productName))]), _c('span', [_vm._v(_vm._s(item.standards))])])];
        },
        proxy: true
      }], null, true)
    });
  }), !_vm.productListGets.length ? _c('van-empty', {
    attrs: {
      "description": "无数据"
    }
  }) : _vm._e()], 2) : _vm._e(), _c('van-cell-group', {
    staticClass: "cell-group"
  }, [_c('van-cell', {
    attrs: {
      "title": "资产名称",
      "value": _vm.currentProduct.productName || '-'
    }
  }), _c('van-cell', {
    attrs: {
      "title": "资产属性",
      "value": _vm.productPropertyMap[_vm.currentProduct.productProperty] || '-'
    }
  }), _c('van-cell', {
    attrs: {
      "title": "主规格",
      "value": _vm.currentProduct.standard || '-'
    }
  }), _vm.currentProduct.productProperty == 3 ? _c('van-cell', {
    attrs: {
      "title": "权属",
      "value": _vm.currentProduct.ownerTypeName || '',
      "is-link": ""
    },
    on: {
      "click": _vm.onOwnerDesListShow
    }
  }) : _vm._e(), _c('van-cell', {
    attrs: {
      "title": "品牌规格详情",
      "value": _vm.currentProduct.standardDesName || '',
      "is-link": ""
    },
    on: {
      "click": _vm.onStandardDesListShow
    }
  }), _c('van-cell', {
    attrs: {
      "title": "资产类型",
      "value": _vm.currentProduct.assetsTypeName || '-'
    }
  }), _vm.currentProduct.productProperty === 3 ? _c('van-cell', {
    attrs: {
      "title": "编号生成方式",
      "required": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function () {
        return [_c('van-radio-group', {
          attrs: {
            "direction": "horizontal"
          },
          on: {
            "change": _vm.onCurrentProductModeChange
          },
          model: {
            value: _vm.currentProduct.mode,
            callback: function ($$v) {
              _vm.$set(_vm.currentProduct, "mode", $$v);
            },
            expression: "currentProduct.mode"
          }
        }, [_c('van-radio', {
          attrs: {
            "name": 0,
            "shape": "square",
            "icon-size": "16px"
          }
        }, [_vm._v("系统自动生成")]), _c('van-radio', {
          attrs: {
            "name": 1,
            "shape": "square",
            "icon-size": "16px"
          }
        }, [_vm._v("已有编号")])], 1)];
      },
      proxy: true
    }], null, false, 763352566)
  }) : _vm._e(), _vm.currentProduct.productProperty === 3 && _vm.currentProduct.mode === 0 ? _c('van-cell', {
    attrs: {
      "title": "编码部门",
      "required": "",
      "value": _vm.currentProduct.dingDeptName || '请选择'
    },
    on: {
      "click": _vm.onDeptListShow
    }
  }) : _vm._e(), _vm.currentProduct.productProperty === 3 && _vm.currentProduct.mode === 1 ? _c('van-cell', {
    attrs: {
      "title": "资产编号",
      "required": "",
      "value": _vm.currentProduct.productNo || '请选择'
    },
    on: {
      "click": _vm.onOutSpaceListShow
    }
  }) : _vm._e(), _c('van-cell', {
    attrs: {
      "title": "盘点库存",
      "required": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function () {
        return [_c('van-stepper', {
          attrs: {
            "integer": "",
            "min": 0,
            "max": _vm.currentProduct.productProperty === 3 && _vm.currentProduct.mode === 1 ? 1 : undefined
          },
          model: {
            value: _vm.currentProduct.count,
            callback: function ($$v) {
              _vm.$set(_vm.currentProduct, "count", $$v);
            },
            expression: "currentProduct.count"
          }
        })];
      },
      proxy: true
    }])
  }), _c('van-cell', {
    attrs: {
      "title": "资产照片",
      "required": _vm.currentProduct.addImgType == 1
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function () {
        return [_c('div', {
          staticClass: "uploadPanel"
        }, [_c('van-uploader', {
          attrs: {
            "max-count": "1",
            "after-read": _vm.afterFileRead
          },
          model: {
            value: _vm.currentProduct.pictureUrls,
            callback: function ($$v) {
              _vm.$set(_vm.currentProduct, "pictureUrls", $$v);
            },
            expression: "currentProduct.pictureUrls"
          }
        })], 1)];
      },
      proxy: true
    }])
  })], 1)], 1)]), _c('van-dialog', {
    attrs: {
      "title": "注意",
      "confirm-button-text": "我已知晓"
    },
    on: {
      "confirm": _vm.onGdListConfirm
    },
    model: {
      value: _vm.gdListShow,
      callback: function ($$v) {
        _vm.gdListShow = $$v;
      },
      expression: "gdListShow"
    }
  }, [_c('div', {
    staticClass: "gdList"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("此次盘点资产中，有以下固定资产被赋予资产编号")]), _c('table', [_c('thead', [_c('tr', [_c('td', [_vm._v("资产名称")]), _c('td', [_vm._v("主规格")]), _c('td', [_vm._v("资产编号")])])]), _c('tbody', _vm._l(_vm.gdList, function (item, index) {
    return _c('tr', {
      key: index
    }, [_c('td', [_vm._v(_vm._s(item.productName || '-'))]), _c('td', [_vm._v(_vm._s(item.standard || '-'))]), _c('td', [_vm._v(_vm._s(item.productNo || '-'))])]);
  }), 0)])])]), _c('van-popup', {
    attrs: {
      "position": "bottom"
    },
    model: {
      value: _vm.deptListShow,
      callback: function ($$v) {
        _vm.deptListShow = $$v;
      },
      expression: "deptListShow"
    }
  }, [_c('van-picker', {
    ref: "deptList",
    attrs: {
      "show-toolbar": "",
      "value-key": "dingDeptName",
      "columns": _vm.deptList
    },
    on: {
      "confirm": _vm.onDeptListSelect,
      "cancel": function ($event) {
        _vm.deptListShow = false;
      }
    }
  })], 1), _c('van-popup', {
    style: {
      height: '50vh'
    },
    attrs: {
      "position": "bottom"
    },
    model: {
      value: _vm.outSpaceListShow,
      callback: function ($$v) {
        _vm.outSpaceListShow = $$v;
      },
      expression: "outSpaceListShow"
    }
  }, [_c('div', {
    staticClass: "productListPanel"
  }, [_c('div', {
    staticClass: "van-picker__toolbar"
  }, [_c('button', {
    staticClass: "van-picker__cancel",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.onProductCancel
    }
  }, [_vm._v("取消")]), _c('div', {
    staticClass: "van-ellipsis van-picker__title"
  }), _c('button', {
    staticClass: "van-picker__confirm",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.onProductConfirm
    }
  }, [_vm._v("确认")])]), _c('div', {
    staticClass: "productListResult"
  }, [_vm._l(_vm.outSpaceList, function (item, index) {
    return _c('van-cell', {
      key: index,
      attrs: {
        "clickable": ""
      },
      on: {
        "click": function ($event) {
          return _vm.onProductCheck(item);
        }
      },
      scopedSlots: _vm._u([{
        key: "title",
        fn: function () {
          return [_c('div', {
            staticClass: "productListResultRow"
          }, [_c('span', {
            staticClass: "c1"
          }, [_vm._v(_vm._s(item.spaceName))]), _vm.currentProductProperty == 3 ? _c('span', {
            staticClass: "c2"
          }, [_vm._v(_vm._s(item.productNo))]) : _vm._e(), _vm.currentProductProperty == 3 ? _c('span', {
            staticClass: "c3"
          }, [_vm._v("￥" + _vm._s(item.actPrice))]) : _c('span', {
            staticClass: "c3"
          }, [_vm._v(_vm._s(item.count))]), _c('van-checkbox', {
            attrs: {
              "value": _vm.checks[item.id]
            }
          })], 1)];
        },
        proxy: true
      }], null, true)
    });
  }), !_vm.outSpaceList.length ? _c('van-empty', {
    attrs: {
      "description": "无数据"
    }
  }) : _vm._e()], 2), _c('van-pagination', {
    attrs: {
      "total-items": _vm.outSpaceListTotal,
      "items-per-page": _vm.outSpaceListPageSize
    },
    on: {
      "change": _vm.onOutSpaceListPage
    },
    model: {
      value: _vm.outSpaceListPageNum,
      callback: function ($$v) {
        _vm.outSpaceListPageNum = $$v;
      },
      expression: "outSpaceListPageNum"
    }
  })], 1)]), _c('van-popup', {
    attrs: {
      "position": "bottom"
    },
    model: {
      value: _vm.standardDesListShow,
      callback: function ($$v) {
        _vm.standardDesListShow = $$v;
      },
      expression: "standardDesListShow"
    }
  }, [_c('van-picker', {
    ref: "standardDesList",
    attrs: {
      "show-toolbar": "",
      "value-key": "name",
      "columns": _vm.standardDesList
    },
    on: {
      "confirm": _vm.onStandardDesListSelect,
      "cancel": function ($event) {
        _vm.standardDesListShow = false;
      }
    }
  }), _c('div', {
    staticClass: "addBtn"
  }, [_c('van-button', {
    attrs: {
      "block": "",
      "plain": "",
      "type": "primary",
      "icon": "add-o"
    },
    on: {
      "click": _vm.onAddStandardDes
    }
  }, [_vm._v("新增")])], 1)], 1), _c('van-popup', {
    attrs: {
      "position": "bottom"
    },
    model: {
      value: _vm.ownerDesListShow,
      callback: function ($$v) {
        _vm.ownerDesListShow = $$v;
      },
      expression: "ownerDesListShow"
    }
  }, [_c('van-picker', {
    ref: "standardDesList",
    attrs: {
      "show-toolbar": "",
      "value-key": "name",
      "columns": _vm.ownerDesList
    },
    on: {
      "confirm": _vm.onOwnerDesListSelect,
      "cancel": function ($event) {
        _vm.ownerDesListShow = false;
      }
    }
  }), _c('div', {
    staticClass: "addBtn"
  }, [_c('van-button', {
    attrs: {
      "block": "",
      "plain": "",
      "type": "primary",
      "icon": "add-o"
    },
    on: {
      "click": _vm.onAddOwnerDes
    }
  }, [_vm._v("新增")])], 1)], 1), _c('van-dialog', {
    attrs: {
      "title": "新增",
      "show-cancel-button": "",
      "before-close": _vm.onAddOwnerDesSubmit
    },
    model: {
      value: _vm.addOwnerDesDialogShow,
      callback: function ($$v) {
        _vm.addOwnerDesDialogShow = $$v;
      },
      expression: "addOwnerDesDialogShow"
    }
  }, [_c('van-field', {
    attrs: {
      "label": "名称",
      "placeholder": "请输入名称"
    },
    model: {
      value: _vm.addOwnerDesValue,
      callback: function ($$v) {
        _vm.addOwnerDesValue = $$v;
      },
      expression: "addOwnerDesValue"
    }
  })], 1), _c('van-dialog', {
    attrs: {
      "title": "新增",
      "show-cancel-button": "",
      "before-close": _vm.onAddStandardDesSubmit
    },
    model: {
      value: _vm.addStandardDesDialogShow,
      callback: function ($$v) {
        _vm.addStandardDesDialogShow = $$v;
      },
      expression: "addStandardDesDialogShow"
    }
  }, [_c('van-field', {
    attrs: {
      "label": "名称",
      "placeholder": "请输入名称"
    },
    model: {
      value: _vm.addStandardDesValue,
      callback: function ($$v) {
        _vm.addStandardDesValue = $$v;
      },
      expression: "addStandardDesValue"
    }
  })], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };